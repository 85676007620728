<template>
  <div class="Partner">
    <Header back="返回" title="合作客户" index="首页" titleOne="合同管理" titleTwo="合作客户" beforeTitle="新增/编辑" />
    <div class="content">
      <h1>客户信息</h1>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="info">
          <el-form
            :rules="baseRule"
            ref="baseRule"
            :model="baseform"
            label-width="80px"
            :label-position="labelPosition"
          >
            <el-form-item label="企业名称" prop="company_name" :error="companyError">
              <el-input @blur="changeCompany" v-model="baseform.company_name" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="企业曾用名" prop="short_name">
              <el-input v-model="baseform.short_name" placeholder="请输入企业曾用名"></el-input>
            </el-form-item>
            <el-form-item label="组织机构代码" prop="org_code">
              <el-input v-model="baseform.org_code" placeholder="请输入组织机构代码"></el-input>
            </el-form-item>
            <el-form-item label="信用代码" prop="code">
              <el-input v-model="baseform.code" placeholder="请输入信用代码"></el-input>
            </el-form-item>
            <el-form-item label="注册资本（万元）" prop="register_capital">
              <el-input v-model="baseform.register_capital" placeholder="请输入注册资本"></el-input>
            </el-form-item>
            <el-form-item label="注册时间" prop="register_time">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                v-model="baseform.register_time"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="是否高企">
              <el-select v-model="baseform.is_high" placeholder="是否高企" @change="isBase">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="高企重新认定时间" prop="reassessment_time" v-if="baseStatus">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                v-model="baseform.reassessment_time"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <div class="clear"></div>
            <el-form-item label="所属区域" prop="province_id">
              <el-select v-model="baseform.province_id" placeholder="请选择省" @change="getcity">
                <el-option
                  v-for="item in province"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="city_id">
              <el-select v-model="baseform.city_id" placeholder="请选择市" @change="gettown">
                <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="town_id">
              <el-select v-model="baseform.town_id" placeholder="请选择区" @change="getstreet">
                <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="baseform.block_id" placeholder="请选择镇/模块">
                <el-option
                  v-for="item in street"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业类型" prop="com_type">
              <el-select v-model="baseform.com_type" placeholder="请选择企业类型">
                <el-option label="有限责任公司" :value="1">有限责任公司</el-option>
                <el-option label="股份有限公司" :value="2">股份有限公司</el-option>
                <el-option label="国企" :value="3">国企</el-option>
                <el-option label="外商投资企业" :value="4">外商投资企业</el-option>
                <el-option label="独资企业" :value="5">独资企业</el-option>
                <el-option label="个体工商户" :value="6">个体工商户</el-option>
                <el-option label="联营企业" :value="7">联营企业</el-option>
                <el-option label="集体所有制" :value="8">集体所有制</el-option>
                <el-option label="有限合" :value="9">有限合伙</el-option>
                <el-option label="普通合伙" :value="10">普通合伙</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营状态" prop="status">
              <el-select v-model="baseform.status" placeholder="请选择经营状态">
                <el-option label="在业" :value="1">在业</el-option>
                <el-option label="存续" :value="2">存续</el-option>
                <el-option label="正常" :value="3">正常</el-option>
                <el-option label="注销" :value="4">注销</el-option>
                <el-option label="筹建" :value="5">筹建</el-option>
                <el-option label="清算" :value="6">清算</el-option>
                <el-option label="迁入" :value="7">迁入</el-option>
                <el-option label="迁出" :value="8">迁出</el-option>
                <el-option label="停业" :value="9">停业</el-option>
                <el-option label="撤销" :value="10">撤销</el-option>
                <el-option label="吊销" :value="11">吊销</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属行业" prop="industry_one">
              <el-select
                v-model="baseform.industry_one"
                placeholder="请选择所属行业"
                @change="getSecondIndustry"
              >
                <el-option
                  v-for="item in oneindustry"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="industry_two">
              <el-select v-model="baseform.industry_two" placeholder="请选择所属行业类型">
                <el-option
                  v-for="item in twoindustry"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产规模" prop="asset_scale">
              <el-select v-model="baseform.asset_scale" placeholder="请选择资产规模">
                <el-option label="2000万元以内" :value="1">2000万元以内</el-option>
                <el-option label="2000万元以上 ~ 5000万元（含）" :value="2">2000万元以上 ~ 5000万元（含）</el-option>
                <el-option label="5000万元以上 ~ 1亿元（含）" :value="3">5000万元以上 ~ 1亿元（含）</el-option>
                <el-option label="1亿元以上 ~ 2亿元（含）" :value="4">1亿元以上 ~ 2亿元（含）</el-option>
                <el-option label="2亿元以上 ~ 4亿元（含）" :value="5">2亿元以上 ~ 4亿元（含）</el-option>
                <el-option label="4亿元以上" :value="6">4亿元以上</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司销售额（万元）">
              <el-input v-model="baseform.sales_scale" placeholder="请输入公司销售额"></el-input>
            </el-form-item>
            <el-form-item label="研发投入（万元）">
              <el-input v-model="baseform.yftr" placeholder="请输入研发投入"></el-input>
            </el-form-item>
            <div class="clear"></div>
            <el-form-item label="法人代表">
              <el-input v-model="baseform.legal_person" placeholder="请输入法人代表"></el-input>
            </el-form-item>
            <el-form-item label="客户联系人">
              <el-input v-model="baseform.business_manager" placeholder="请输入客户联系人"></el-input>
            </el-form-item>
            <el-form-item label="客户联系人手机">
              <el-input v-model="baseform.tel" placeholder="请输入客户联系人手机"></el-input>
            </el-form-item>
            <el-form-item label="客服管家">
              <el-input v-model="baseform.project_specialist" placeholder="请输入客服管家"></el-input>
            </el-form-item>
            <el-form-item label="企业联系人">
              <el-input v-model="baseform.contact" placeholder="请输入企业联系人"></el-input>
            </el-form-item>
            <el-form-item label="企业固定电话">
              <el-input v-model="baseform.mobile" placeholder="请输入企业固定电话"></el-input>
            </el-form-item>
            <el-form-item label="企业邮箱">
              <el-input v-model="baseform.email" placeholder="请输入企业邮箱"></el-input>
            </el-form-item>
            <el-form-item label="企业网址">
              <el-input v-model="baseform.domain" placeholder="请输入企业网址"></el-input>
            </el-form-item>
            <el-form-item label="专利数量">
              <el-input v-model="baseform.patent_count" placeholder="请输入专利数量"></el-input>
            </el-form-item>
            <el-form-item label="软著数量">
              <el-input v-model="baseform.soft_count" placeholder="请输入软著数量"></el-input>
            </el-form-item>
            <el-form-item label="商标数量">
              <el-input v-model="baseform.brand_count" placeholder="请输入商标数量"></el-input>
            </el-form-item>
            <el-form-item label="登记机关">
              <el-input v-model="baseform.register_org" placeholder="请输入登记机关"></el-input>
            </el-form-item>
            <el-form-item label="注册地址" style="width: 48%" placeholder="请输入注册地址">
              <el-input v-model="baseform.register_address"></el-input>
            </el-form-item>
            <el-form-item label="公司地址" style="width: 48%" placeholder="请输入公司地址">
              <el-input v-model="baseform.address"></el-input>
            </el-form-item>
            <el-form-item label="经营范围" style="width: 100%">
              <el-input
                type="textarea"
                v-model="baseform.jyfw"
                :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="请输入经营范围"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="foot_TJ">
            <el-button
              type="primary"
              @click="baseSubmit"
              :loading="baseLoading"
              :disabled="isDisable"
            >确定</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人员花名册" name="roster" :disabled="!meber_id">
          <div class="topUsers">
            <div style="display:flex;width:90%" class="top_header">
              <div>
                <el-select
                  clearable
                  v-model="rosterParams.education"
                  placeholder="请选择学历类型"
                  popper-class="select_box"
                >
                  <el-option label="博士" value="1"></el-option>
                  <el-option label="硕士" value="2"></el-option>
                  <el-option label="本科" value="3"></el-option>
                  <el-option label="专科及以下" value="4"></el-option>
                </el-select>
              </div>
              <div>
                <el-select clearable v-model="rosterParams.type" placeholder="请选择人员类型">
                  <el-option label="在职人员" value="1"></el-option>
                  <el-option label="兼职人员" value="2"></el-option>
                  <el-option label="临时聘用人员" value="3"></el-option>
                  <el-option label="外籍人员" value="4"></el-option>
                  <el-option label="留学归国人员" value="5"></el-option>
                  <el-option label="千人计划人员" value="6"></el-option>
                </el-select>
              </div>
              <div>
                <el-select clearable v-model="rosterParams.is_science" placeholder="请选择是否科技人员">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </div>
              <div>
                <el-select clearable v-model="rosterParams.insured" placeholder="请选择是否参保">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </div>
              <el-input
                clearable
                class="import"
                v-model="rosterParams.keywords"
                placeholder="请输入人员姓名"
                @keyup.enter.native="handleSearch()"
              ></el-input>
              <el-button class="search" @click="handleSearch()">查询</el-button>
            </div>
            <div>
              <el-button type="primary" class="add" @click="handleEdit('')">新增</el-button>
            </div>
          </div>
          <el-table :data="tableDataMan.data" style="width: 100%">
            <el-table-column prop="name" label="姓名" width="130"></el-table-column>
            <el-table-column prop="identity_code" label="身份证号" width="180"></el-table-column>
            <el-table-column prop="birthday" show-overflow-tooltip label="出生年月">
              <template slot-scope="scope">{{scope.row.birthday?scope.row.birthday:'-'}}</template>
            </el-table-column>
            <el-table-column prop="tel" label="手机号">
              <template slot-scope="scope">{{scope.row.tel?scope.row.tel:'-'}}</template>
            </el-table-column>
            <el-table-column prop="entry_time" show-overflow-tooltip label="入职时间">
              <template slot-scope="scope">{{scope.row.entry_time?scope.row.entry_time:'-'}}</template>
            </el-table-column>
            <el-table-column prop="quit_time" show-overflow-tooltip label="离职时间">
              <template slot-scope="scope">{{scope.row.quit_time?scope.row.quit_time:'-'}}</template>
            </el-table-column>
            <el-table-column prop="title" label="职称" width="100px">
              <template slot-scope="scope">
                <span v-if="scope.row.title == 1">高级</span>
                <span v-if="scope.row.title == 2">中级</span>
                <span v-if="scope.row.title == 3">初级</span>
                <span v-if="scope.row.title == 4">高级技工</span>
              </template>
            </el-table-column>

            <el-table-column prop="education" label="学历" width="100px">
              <template slot-scope="scope">
                <span v-if="scope.row.education == 1">博士</span>
                <span v-if="scope.row.education == 2">硕士</span>
                <span v-if="scope.row.education == 3">本科</span>
                <span v-if="scope.row.education == 4">大专及以下</span>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="人员类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">在职</span>
                <span v-if="scope.row.type == 2">兼职</span>
                <span v-if="scope.row.type == 3">临时</span>
                <span v-if="scope.row.type == 4">外籍</span>
                <span v-if="scope.row.type == 5">留学归国</span>
                <span v-if="scope.row.type == 6">千人计划</span>
              </template>
            </el-table-column>
            <el-table-column prop="is_science" label="是否科技人员" width="110px">
              <template slot-scope="scope">
                <span v-if="scope.row.is_science == 1">是</span>
                <span v-if="scope.row.is_science == 0">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="insured" label="是否参保" width="90px">
              <template slot-scope="scope">
                <span v-if="scope.row.insured == 1">是</span>
                <span v-if="scope.row.insured == 0">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="240px">
              <template slot-scope="scope">
                <el-button class="edit" size="mini" @click="handleEdits(scope.row.id)">编辑</el-button>
                <el-button class="del" size="mini" @click="handleDeletes(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="rosterParams.page"
              :page-sizes="[2, 10, 20, 40]"
              :page-size="rosterParams.limit"
              layout="sizes, prev, pager, next,total,jumper"
              :total="tableDataMan.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人员资源" name="human" :disabled="!meber_id">
          <el-form
            :rules="humanRule"
            ref="humanRule"
            :model="humanform"
            label-width="80px"
            :label-position="labelPosition"
          >
            <h2>总体情况</h2>
            <dl>
              <dt style="color: #404040">人员类型</dt>
              <dt style="color: #404040">企业职工（人）</dt>
              <dt style="color: #404040">科技人员（人）</dt>
            </dl>
            <dl>
              <dd>总人数</dd>
              <dd>
                <span>{{peopleNum}}</span>
              </dd>
              <dd>
                <span>{{humanNum}}</span>
              </dd>
            </dl>
            <dl>
              <dd>其中：在职人员</dd>
              <dd>
                <span>{{humanform.q_zz}}</span>
              </dd>
              <dd>
                <span>{{humanform.k_zz}}</span>
              </dd>
            </dl>
            <dl>
              <dd>兼职人员</dd>
              <dd>
                <span>{{humanform.q_jz}}</span>
              </dd>
              <dd>
                <span>{{humanform.k_jz}}</span>
              </dd>
            </dl>
            <dl>
              <dd>临时聘用人员</dd>
              <dd>
                <span>{{humanform.q_ls}}</span>
              </dd>
              <dd>
                <span>{{humanform.k_ls}}</span>
              </dd>
            </dl>
            <dl>
              <dd>外籍人员</dd>
              <dd>
                <span>{{humanform.q_wj}}</span>
              </dd>
              <dd>
                <span>{{humanform.k_wj}}</span>
              </dd>
            </dl>
            <dl>
              <dd>留学归国人员</dd>
              <dd>
                <span>{{humanform.q_lx}}</span>
              </dd>
              <dd>
                <span>{{humanform.k_lx}}</span>
              </dd>
            </dl>
            <dl>
              <dd>千人计划人员</dd>
              <dd>
                <span>{{humanform.q_gr}}</span>
              </dd>
              <dd>
                <span>{{humanform.k_gr}}</span>
              </dd>
            </dl>
            <h2 style="margin-top:40px">全体人员结构</h2>
            <dl>
              <dt style="color: #404040">结构类型</dt>
              <dt style="color: #404040">人数</dt>
            </dl>
            <dl>
              <dd>博士</dd>
              <dd>
                <span>{{humanform.x_bs}}</span>
              </dd>
            </dl>
            <dl>
              <dd>硕士</dd>
              <dd>
                <span>{{humanform.x_ss}}</span>
              </dd>
            </dl>
            <dl>
              <dd>本科</dd>
              <dd>
                <span>{{humanform.x_bk}}</span>
              </dd>
            </dl>
            <dl>
              <dd>大专及以下</dd>
              <dd>
                <span>{{humanform.x_dz}}</span>
              </dd>
            </dl>
            <dl>
              <dd>高级职称</dd>
              <dd>
                <span>{{humanform.z_gj}}</span>
              </dd>
            </dl>
            <dl>
              <dd>中级职称</dd>
              <dd>
                <span>{{humanform.z_zj}}</span>
              </dd>
            </dl>
            <dl>
              <dd>初级职称</dd>
              <dd>
                <span>{{humanform.z_cj}}</span>
              </dd>
            </dl>
            <dl>
              <dd>高级技工</dd>
              <dd>
                <span>{{humanform.z_jg}}</span>
              </dd>
            </dl>
            <dl>
              <dd>30岁及以下</dd>
              <dd>
                <span>{{humanform.l_one}}</span>
              </dd>
            </dl>
            <dl>
              <dd>31岁-40岁</dd>
              <dd>
                <span>{{humanform.l_two}}</span>
              </dd>
            </dl>
            <dl>
              <dd>41岁-50岁</dd>
              <dd>
                <span>{{humanform.l_three}}</span>
              </dd>
            </dl>
            <dl>
              <dd>51岁及以上</dd>
              <dd>
                <span>{{humanform.l_four}}</span>
              </dd>
            </dl>
            <h2 style="margin-top:40px">
              企业职工人数情况表
              <!-- <el-button class="Import">导出</el-button> -->
            </h2>
            <dl>
              <dt style="color: #404040">企业职工情况类型</dt>
              <dt style="color: #404040">人数</dt>
            </dl>
            <dl>
              <dd>全年月平均在职人员①</dd>
              <dd>
                <span>{{humanform.qly}}</span>
              </dd>
            </dl>
            <dl>
              <dd>其中，参保人员</dd>
              <dd>
                <span>{{humanform.cbrs}}</span>
              </dd>
            </dl>
            <dl>
              <dd>工作时间超过183天的兼职人员②</dd>
              <dd>
                <span>{{humanform.jzrs}}</span>
              </dd>
            </dl>
            <dl>
              <dd>工作时间超过183天的临时聘用人员③</dd>
              <dd>
                <span>{{humanform.lsrs}}</span>
              </dd>
            </dl>
            <dl>
              <dd>企业职工总数（①+②+③）</dd>
              <dd>
                <span>{{humanform.qyzrs}}</span>
              </dd>
            </dl>
            <dl style="display:flex">
              <dd style="width:25%">全年月平均在职人员数与参保人数差异的情况说明</dd>
              <span style="detail_desc" v-if="humanform.qksm">{{humanform.qksm}}</span>
              <span style="detail_desc" v-if="!humanform.qksm || humanform.qksm == null">暂无说明</span>
            </dl>
          </el-form>
          <div class="foot_TJ">
            <el-button
              type="primary"
              :loading="humanBtnLoading"
              @click="humanSubmit"
              :disabled="isDisable"
            >确定</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="联系信息" name="contact" :disabled="!meber_id">
          <h2>企业负责人</h2>
          <el-form
            :rules="contactRule"
            ref="contactRule"
            :model="contactform"
            label-width="80px"
            :label-position="labelPosition"
          >
            <el-form-item style="display: none">
              <el-input v-model="lx_company_manager.type" value="1"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="lx_company_manager.contact_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="lx_company_manager.contact_birthday"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="lx_company_manager.contact_tel" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="lx_company_manager.contact_email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="性格爱好特点" style="width: 100%">
              <el-input
                type="textarea"
                v-model="lx_company_manager.contact_character"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
            <h2>企业联系人</h2>
            <el-form-item style="display: none">
              <el-input v-model="lx_company_contact.type" value="2"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="lx_company_contact.contact_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="lx_company_contact.contact_birthday"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="lx_company_contact.contact_tel" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="lx_company_contact.contact_email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="性格爱好特点" style="width: 100%">
              <el-input
                type="textarea"
                v-model="lx_company_contact.contact_character"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
            <h2>项目对接人</h2>
            <el-form-item style="display: none">
              <el-input v-model="lx_project_manager.type" value="3"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="lx_project_manager.contact_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                v-model="lx_project_manager.contact_birthday"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="lx_project_manager.contact_tel" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="lx_project_manager.contact_email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="性格爱好特点" style="width: 100%">
              <el-input
                type="textarea"
                v-model="lx_project_manager.contact_character"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
            <h2>财务负责人</h2>
            <el-form-item style="display: none">
              <el-input v-model="lx_finance_manager.type" value="4"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="lx_finance_manager.contact_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                v-model="lx_finance_manager.contact_birthday"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="lx_finance_manager.contact_tel" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="lx_finance_manager.contact_email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="性格爱好特点" style="width: 100%">
              <el-input
                type="textarea"
                v-model="lx_finance_manager.contact_character"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
            <h2>技术负责人</h2>
            <el-form-item style="display: none">
              <el-input v-model="lx_tech_manager.type" value="4"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="lx_tech_manager.contact_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                v-model="lx_tech_manager.contact_birthday"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="lx_tech_manager.contact_tel" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="lx_tech_manager.contact_email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="性格爱好特点" style="width: 100%">
              <el-input
                type="textarea"
                v-model="lx_tech_manager.contact_character"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="foot_TJ">
            <el-button
              type="primary"
              :loading="contactBtnLoading"
              @click="contactSubmit"
              :disabled="isDisable"
            >确定</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="业务拜访" name="business" :disabled="!meber_id">
          <h2>业务情况</h2>
          <el-form
            :rules="visitRule"
            ref="visitRule"
            :model="visitform"
            label-width="80px"
            :label-position="labelPosition"
          >
            <el-form-item label="客户联系人">
              <el-input v-model="visitform.custom_manager" placeholder="请输入客户联系人"></el-input>
            </el-form-item>
            <el-form-item label="客户级别" prop="level">
              <el-select v-model="visitform.level" placeholder="请选择">
                <el-option label="A" value="A">A</el-option>
                <el-option label="B" value="B">B</el-option>
                <el-option label="C" value="C">C</el-option>
                <el-option label="D" value="D">D</el-option>
                <el-option label="E" value="E">E</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="拜访次数">
              <el-input v-model="visitform.visit_count" placeholder="请输入拜访次数"></el-input>
            </el-form-item>
            <el-form-item label="客户来源">
              <el-select v-model="visitform.custom_source" placeholder="请选择">
                <el-option label="自主开发" :value="1">自主开发</el-option>
                <el-option label="转介绍" :value="2">转介绍</el-option>
                <el-option label="渠道推介" :value="3">渠道推介</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="自主开放方式" v-if="visitform.custom_source == 1">
              <el-select v-model="visitform.development_model" placeholder="请选择自主开发方式">
                <el-option label="电话" :value="1">电话</el-option>
                <el-option label="活动" :value="2">活动</el-option>
                <el-option label="线上" :value="3">线上</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户来源" v-if="visitform.custom_source == 2">
              <el-input v-model="visitform.introducer" placeholder="请输入转介绍人"></el-input>
            </el-form-item>
            <!-- visit_count渠道名称替换 -->
            <el-form-item label="渠道推荐" v-if="visitform.custom_source == 3">
              <el-input v-model="visitform.channel" placeholder="请输入渠道名称"></el-input>
            </el-form-item>

            <h2>拜访情况</h2>
            <el-table :data="visitform.visit" style="width: 100%" :key="1">
              <el-table-column width="50">
                <template slot="header" slot-scope="scope">
                  <button type="button" @click="addLine" class="addBtn">
                    <i class="addLine"></i>
                  </button>
                </template>
                <template slot-scope="scope">
                  <button
                    v-if="visitform.visit.length > 0"
                    @click="handleDelete(scope.row.id)"
                    class="del-btn"
                  >
                    <i class="delLine"></i>
                  </button>
                </template>
              </el-table-column>
              <el-table-column label="时间">
                <template slot-scope="scope">
                  <el-date-picker
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    v-model="scope.row.visit_time"
                    style="width: 100%"
                  ></el-date-picker>
                </template>
              </el-table-column>
              <el-table-column label="地点">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.visit_place" placeholder="请输入地点"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="拜访人员">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.visit_person" placeholder="请输入拜访人员"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="拜访纪要">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.visit_summary" placeholder="请输入拜访纪要"></el-input>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="operation" label="附件" width="250px">
								<template slot-scope="scope">
									<el-upload class="upload-demo" ref="imgupload" action="string" :name="''+scope.$index" :http-request="Uploadfile" :show-file-list="false" :before-upload="beforeAvatarUpload" :file-list="fileList">
										<el-button type="primary" class="fileUpload">上传</el-button>
									</el-upload>
									<el-link v-if="scope.row.url" :href="userSite + scope.row.url"><el-button class="edit" size="mini">查看</el-button></el-link>
								</template>
              </el-table-column>-->
            </el-table>
          </el-form>
          <div class="foot_TJ">
            <el-button
              type="primary"
              :loading="visitBtnLoading"
              @click="visitSubmit"
              :disabled="isDisable"
            >确定</el-button>
          </div>
        </el-tab-pane>

        <el-tab-pane label="客户资料" name="customInfo">
          <Custominfo ref="customInfo" :com_id="$route.query.id" />
        </el-tab-pane>
        <el-tab-pane label="项目列表" name="list">
          <List ref="list" :com_id="$route.query.id" />
        </el-tab-pane>
      </el-tabs>
      <el-dialog title="新增人员" :visible.sync="dialogTableVisible" width="400px">
        <el-form :model="rosterForm" class="roster_form" :rules="rosterRules">
          <el-form-item label="姓名" prop="name">
            <el-input placeholder="请输入姓名" v-model="rosterForm.name"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="identity_code">
            <el-input placeholder="请输入身份证号码" v-model="rosterForm.identity_code"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="display:block;font-size:16px">出生年月</span>
            <el-date-picker
              style="width:100%"
              v-model="rosterForm.birthday"
              type="date"
              placeholder="选择出生年月"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input placeholder="请输入手机号码" v-model="rosterForm.tel"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="display:block;font-size:16px">入职时间</span>
            <el-date-picker
              type="date"
              style="width:100%"
              value-format="yyyy-MM-dd"
              v-model="rosterForm.entry_time"
              placeholder="选择入职时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <span style="display:block;font-size:16px">离职时间</span>
            <el-date-picker
              type="date"
              style="width:100%"
              value-format="yyyy-MM-dd"
              v-model="rosterForm.quit_time"
              placeholder="选择离职时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="学历">
            <el-select v-model="rosterForm.education" placeholder="请选择学历">
              <el-option label="博士" :value="1"></el-option>
              <el-option label="硕士" :value="2"></el-option>
              <el-option label="本科" :value="3"></el-option>
              <el-option label="大专及以下" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职称">
            <el-select v-model="rosterForm.title" placeholder="请选择职称">
              <el-option label="高级职称" :value="1"></el-option>
              <el-option label="中级职称" :value="2"></el-option>
              <el-option label="初级职称" :value="3"></el-option>
              <el-option label="高级技工" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="人员类型">
            <el-select v-model="rosterForm.type" placeholder="请选择人员类型">
              <el-option label="在职人员" :value="1"></el-option>
              <el-option label="兼职人员" :value="2"></el-option>
              <el-option label="临时聘用人员" :value="3"></el-option>
              <el-option label="外籍人员" :value="4"></el-option>
              <el-option label="留学归国人员" :value="5"></el-option>
              <el-option label="千人计划人员" :value="6"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否科技人员">
            <el-select v-model="rosterForm.is_science" placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否参保">
            <el-select v-model="rosterForm.insured" placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" :loading="rosterBtnLoading" @click="rosterAddSure()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import site from "../../components/global.vue";
import Custominfo from './customInfoTab.vue'
import List from './partnerListTab.vue'
export default {
  data () {
    return {
      baseLoading: false,//基本资料提交按钮动画
      rosterBtnLoading: false,//人员花名册新增提交按钮动画
      humanBtnLoading: false,//人员资源提交按钮动画
      contactBtnLoading: false,//联系信息提交按钮动画
      visitBtnLoading: false,//业务拜访提交按钮动画
      companyError: '',
      roster_id: '',
      dialogTableVisible: false,//人员花名册新增弹窗
      rosterForm: {
      },//人员花名册新增表单
      tableDataMan: [],//人员花名册表格列表存储
      companyParams: {},//人员花名册
      isDisable: false,
      userSite: site.userSite,
      activeName: "info",
      labelPosition: "top",
      input: "",
      //first
      baseform: {
        company_name: "",
        short_name: "",
        org_code: "",
        code: "",
        register_capital: "",
        register_time: "",
        is_high: "",
        province_id: "",
        city_id: "",
        town_id: "",
        block_id: "",
        com_type: "",
        status: "",
        industry_one: "",
        industry_two: "",
        asset_scale: "",
        sales_scale: "",
        yftr: "",
        legal_person: "",
        business_manager: "",
        tel: "",
        project_specialist: "",
        contact: "",
        mobile: "",
        email: "",
        domain: "",
        patent_count: "",
        soft_count: "",
        brand_count: "",
        register_org: "",
        register_address: "",
        address: "",
        jyfw: "",
        reassessment_time: "",//高企重新认定时间
      },
      baseStatus: 1,
      baseRule: {
        company_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入信用代码",
            trigger: "blur",
          },
        ],
        register_capital: [
          {
            required: true,
            message: "请输入注册资本",
            trigger: "blur",
          },
        ],
        register_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: true,
            message: "请选择省",
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: "请选择市",
            trigger: "change",
          },
        ],
        town_id: [
          {
            required: true,
            message: "请选择区",
            trigger: "change",
          },
        ],
        com_type: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择经营状态",
            trigger: "change",
          },
        ],
        industry_one: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change",
          },
        ],
        industry_two: [
          {
            required: true,
            message: "请选择所属行业类型",
            trigger: "change",
          },
        ],
      },
      province: [],
      city: [],
      town: [],
      street: [],
      oneindustry: [],
      twoindustry: [],

      humanform: {
        q_zs: "",
        k_zs: "",
        q_zz: "",
        k_zz: "",
        q_jz: "",
        k_jz: "",
        q_ls: "",
        k_ls: "",
        q_wj: "",
        k_wj: "",
        q_lx: "",
        k_lx: "",
        q_gr: "",
        k_gr: "",
        x_bs: "",
        x_ss: "",
        x_bk: "",
        x_dz: "",
        z_gj: "",
        z_zj: "",
        z_cj: "",
        z_jg: "",
        l_one: "",
        l_two: "",
        l_three: "",
        l_four: "",
        qly: "",
        cbrs: "",
        jzrs: "",
        lsrs: "",
        qyzrs: "",
        qksm: "",
        com_id: "",
      },
      humanRule: {},
      //third
      contactform: {
        contact: [
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 1,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 2,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 3,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 4,
          },
          {
            contact_name: "",
            contact_birthday: "",
            contact_tel: "",
            contact_email: "",
            contact_character: "",
            type: 5,
          },
        ],
        com_id: "",
      },
      lx_company_manager: {},
      lx_company_contact: {},
      lx_project_manager: {},
      lx_finance_manager: {},
      lx_tech_manager: {},
      contactRule: {},
      visitform: {
        // visit: new Array(),
        visit: [],
        custom_manager: "",
        level: "",
        custom_source: "",
        visit_count: "",
        introducer: "",
        com_id: "",
        file_name: "",
        url: "",
        file_id: "",
      },
      url: "",
      visitRule: {
        level: [
          {
            required: true,
            message: "请选择客户级别",
            trigger: "change",
          },
        ],
      },
      fileList: [],
      rosterParams: {
        com_id: this.$route.query.id,
        token: localStorage.getItem('token'),
        page: 1,
        limit: 10,
        keywords: '',
        insured: '',//参保
        is_science: '',//科技人员
        type: '',//人员类型
      },
      rosterRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        identity_code: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
        ]
      },//花名册新增规则
      meber_id: '',
    };
  },
  components: {
    Custominfo,
    List,
  },
  created () {
    this.meber_id = this.$route.query.id
    this.getprovince();
    this.getFirstIndustry();
    this.getRosterList();
    if (this.$route.query.name) {
      this.activeName = this.$route.query.name
    }
  },
  watch: {
    "baseform.province_id": {
      handler (val, oval) {
        this.getcity(val);
      },
      deep: true,
    },
    "baseform.city_id": {
      handler (val, oval) {
        this.gettown(val);
      },
      deep: true,
    },
    "baseform.town_id": {
      handler (val, oval) {
        this.getstreet(val);
      },
      deep: true,
    },
    "baseform.industry_one": {
      handler (val, oval) {
        this.getSecondIndustry(val);
      },
      deep: true,
    },
  },
  computed: {
    // meber_id () {
    //   return this.$route.query.id;
    // },
    peopleNum: function () {
      let sum = 0;
      let sum1 = parseFloat(this.humanform.q_zz) || 0;
      let sum2 = parseFloat(this.humanform.q_jz) || 0;
      let sum3 = parseFloat(this.humanform.q_ls) || 0;
      let sum4 = parseFloat(this.humanform.q_wj) || 0;
      let sum5 = parseFloat(this.humanform.q_lx) || 0;
      let sum6 = parseFloat(this.humanform.q_gr) || 0;
      sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6;
      console.log(111);
      return sum || 0;
    },
    humanNum: function () {
      let sum = 0;
      let sum1 = parseFloat(this.humanform.k_zz) || 0;
      let sum2 = parseFloat(this.humanform.k_jz) || 0;
      let sum3 = parseFloat(this.humanform.k_ls) || 0;
      let sum4 = parseFloat(this.humanform.k_wj) || 0;
      let sum5 = parseFloat(this.humanform.k_lx) || 0;
      let sum6 = parseFloat(this.humanform.k_gr) || 0;
      sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6;
      console.log(111);
      return sum || 0;
    },
  },

  mounted () {
    if (this.meber_id) {
      // 这个是一个页面查看的接口
      this.axios
        .get("/api/company/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.baseform = res.data;
          this.baseStatus = res.data.is_high
        });
    }
  },
  methods: {
    changeCompany () {
      this.axios.get('/api/company/select_company', { params: { keywords: this.baseform.company_name } }).then((res) => {
        if (res.code == 1 && !this.meber_id) {
          if (res.data.find((item) => item.company_name == this.baseform.company_name).company_name == this.baseform.company_name) {
            this.$message.warning('企业名称已存在')
            this.companyError = '企业名称已存在'
          }
        }
      })
    },
    handleSizeChange (val) {
      this.rosterParams.limit = val;
      this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams } }).then((res) => {
        this.tableDataMan = res.data
      })
    },
    handleCurrentChange (val) {
      this.rosterParams.page = val;
      this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams } }).then((res) => {
        this.tableDataMan = res.data
      })
    },
    handleEdits (id) {
      this.dialogTableVisible = true
      this.axios.get('/api/company/view_company_person', { params: { id: id } }).then((res) => {
        this.rosterForm = res.data
        this.getRosterList()
      })
    },
    handleDeletes (id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/company/delete_company_person', { params: { id: id } }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getRosterList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 人员花名册表格数据
    getRosterList () {
      this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams } }).then((res) => {
        this.tableDataMan = res.data
      })
    },
    // 人员花名册新增确定按钮
    rosterAddSure () {
      this.rosterBtnLoading = true
      if (this.$route.query.id) {
        this.axios.post('/api/company/store_company_person', {
          ...this.rosterForm,
          com_id: +this.$route.query.id
        }).then((res) => {
          this.rosterBtnLoading = false;
          if (res.code == 1) {
            this.$message.success(res.message)
          } else if (res.code == 0) {
            this.$message.error(res.message)
          }
          this.dialogTableVisible = false
          this.getRosterList()
        })
      } else if (this.meber_id) {
        this.axios.post('/api/company/store_company_person', {
          ...this.rosterForm,
          com_id: this.meber_id
        }).then((res) => {
          this.rosterBtnLoading = false
          if (res.code == 1) {
            this.$message.success(res.message)
          } else if (res.code == 0) {
            this.$message.error(res.message)
          }
          this.dialogTableVisible = false
          this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams, com_id: this.meber_id } }).then((res) => {
            this.tableDataMan = res.data
          })
        })
      }
    },
    // 人员花名册新增
    handleEdit () {
      this.dialogTableVisible = true
      this.rosterForm = {}
    },
    // 人员花名册查询
    handleSearch () {
      this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams } }).then((res) => {
        console.log(res, '花名册数据')
        this.tableDataMan = res.data
      })
    },
    //是否高企 radio按钮
    isBase (a) {
      this.baseStatus = a
    },
    //first
    baseSubmit () {
      this.baseLoading = true
      this.$refs.baseRule.validate((vaild) => {
        this.baseform.is_cooperation = 1;
        if (vaild) {
          this.axios.get('/api/company/select_company', { params: { keywords: this.baseform.company_name } }).then((res) => {
            if (res.code == 1 && !this.meber_id) {
              // console.log(res.data.find((item) => item.company_name == this.baseform.company_name).company_name)
              if (res.data.find((item) => item.company_name == this.baseform.company_name).company_name == this.baseform.company_name) {
                this.$message.warning('企业名称已存在')
                this.baseLoading = false
              }
            } else {
              this.axios
                .post("/api/company/store", this.baseform)
                .then((res) => {
                  this.humanform.com_id = res.data.id;
                  this.contactform.com_id = res.data.id;
                  this.visitform.com_id = res.data.id;
                  // console.log(this.meber_id, 11111)
                  this.meber_id = res.data.id
                  // console.log(this.meber_id, 2222)
                  localStorage.setItem("getcomid", res.data.id);
                  this.$message({
                    type: "success",
                    message: "保存成功，请填写人员花名册!",
                  });
                  this.baseLoading = false
                  // this.isDisable = true;
                })
                .catch((res) => {
                  console.log("err:", res);
                  this.baseLoading = false
                });
            }
          })
        } else {
          this.baseLoading = false
          this.$message.warning('请完善必填信息')
        }
      });
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
        });
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
        });
    },
    getstreet (id) {
      this.axios
        .get("/api/region/getBlock", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.street = res.data;
        });
    },
    getFirstIndustry () {
      this.axios.get("/api/region/getFirstIndustry").then((res) => {
        this.oneindustry = res.data;
      });
    },
    getSecondIndustry (id) {
      this.axios
        .get("/api/region/getSecondIndustry", {
          params: {
            industry_id: id,
          },
        })
        .then((res) => {
          this.twoindustry = res.data;
        });
    },
    //second
    humanSubmit () {
      this.humanBtnLoading = true
      this.$refs.humanRule.validate((vaild) => {
        if (vaild) {
          this.humanform.q_zs = this.peopleNum;
          this.humanform.k_zs = this.humanNum;
          this.axios
            .post("/api/company/store_human", this.humanform)
            .then((res) => {
              this.humanBtnLoading = false
              this.$message({
                type: "success",
                message: "保存成功，请填写联系信息表!",
              });
              this.isDisable = true;
            })
            .catch((res) => {
              console.log("err:", res);
              this.humanBtnLoading = false
            });
        } else {
          this.humanBtnLoading = false
        }
      });
    },
    //third
    contactSubmit () {
      this.$refs.contactRule.validate((vaild) => {
        if (vaild) {
          this.contactform.contact[0] = this.lx_company_manager;
          this.contactform.contact[1] = this.lx_company_contact;
          this.contactform.contact[2] = this.lx_project_manager;
          this.contactform.contact[3] = this.lx_finance_manager;
          this.contactform.contact[4] = this.lx_tech_manager;
          this.contactform.contact[0].type = 1;
          this.contactform.contact[1].type = 2;
          this.contactform.contact[2].type = 3;
          this.contactform.contact[3].type = 4;
          this.contactform.contact[4].type = 5;
          console.log(this.contactform);
          console.log(this.contactform.contact[4]["contact_character"]);
          this.contactBtnLoading = true
          this.axios
            .post("/api/company/store_contact", this.contactform)
            .then((res) => {
              console.log(res);
              this.$message({
                type: "success",
                message: "保存成功，请填写业务拜访表!",
              });
              this.isDisable = true;
              this.contactBtnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.contactBtnLoading = false
            });
        }
      });
    },
    //fourth
    visitSubmit () {
      this.$refs.visitRule.validate((vaild) => {
        if (vaild) {
          this.visitBtnLoading = true
          this.axios
            .post("/api/company/store_business", this.visitform)
            .then((res) => {
              this.$message.success(res.message)
              this.$router.push("/Partner/index");
              this.isDisable = true;
              this.visitBtnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.visitBtnLoading = false
            });
        }
      });
    },
    handleClick (tab, event) {
      this.$refs[this.activeName].getList()
      if (this.activeName == "human" && this.meber_id) {
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            (this.humanform = res.data ? res.data : {}),
              (this.humanform.com_id = this.meber_id);
          });
      } if (this.activeName == "contact" && this.meber_id) {
        console.log(this.activeName);
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            // this.$forceUpdate()
            const dafult_com_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 1,
            };
            const dafult_com_cont = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 2,
            };
            const dafult_pro_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 3,
            };
            const dafult_fin_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 4,
            };
            const dafult_tech_man = {
              contact_name: "",
              contact_birthday: "",
              contact_tel: "",
              contact_email: "",
              contact_character: "",
              type: 5,
            };
            this.contactform.com_id = this.meber_id;
            this.lx_company_manager =
              res.data.company_manager.length > 0
                ? res.data.company_manager[0]
                : dafult_com_man;
            this.lx_company_contact =
              res.data.company_contact.length > 0
                ? res.data.company_contact[0]
                : dafult_com_cont;
            this.lx_project_manager =
              res.data.project_manager.length > 0
                ? res.data.project_manager[0]
                : dafult_pro_man;
            this.lx_finance_manager =
              res.data.finance_manager.length > 0
                ? res.data.finance_manager[0]
                : dafult_fin_man;
            this.lx_tech_manager =
              res.data.tech_manager.length > 0
                ? res.data.tech_manager[0]
                : dafult_tech_man;
          });
      } if (this.activeName == "business" && this.meber_id) {
        this.visitform.com_id = this.meber_id;
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            this.visitform = res.data;
            console.log(this.visitform);
            this.visitform.com_id = this.meber_id;
            this.visitform.visit.forEach((item) => { });
          });
      } if (this.activeName == 'roster' && this.$route.query.id) {
        console.log('编辑')
        this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams, com_id: this.$route.query.id } }).then((res) => {
          this.tableDataMan = res.data
        })
      } if (this.activeName == 'roster' && !this.$route.query.id) {
        console.log('新增')
        this.tableDataMan = []
        if (this.meber_id) {
          this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams, com_id: this.meber_id } }).then((res) => {
            this.tableDataMan = res.data
          })
        }
      }
    },
    Uploadfile (param) {
      let file = param.file;
      let cindex = param.filename;
      const form = new FormData();
      form.append("file", file);
      form.append("type", "3");
      console.log(param);
      this.upload("/api/patent/upload_file", form).then((res) => {
        console.log(res);
        this.visitform.visit[cindex].url = res.data.url;
        this.visitform.visit[cindex].file_name = res.data.name;
        this.visitform.visit[cindex].file_id = res.data.id;
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    addLine () {
      //添加行数
      var newValue = {};
      this.visitform.visit.push(newValue);
    },
    handleDelete (index) {
      //删除行数
      this.visitform.visit.splice(index, 1);
    },
    save () {
      //这部分应该是保存提交你添加的内容
      console.log(JSON.stringify(this.visitform.visit));
    },
  },
};
</script>

<style scoped="scoped">
.Partner dl {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Partner dl dt {
  display: inline-block;
  width: 25%;
  color: #a6abc7;
}

.Partner dl dd {
  display: inline-block;
  width: 25%;
  margin: 0 !important;
  font-weight: bold;
}

.Partner dl dt:nth-child(2) {
  margin-right: 2% !important;
}

.Partner dl dd:nth-child(2) {
  margin-right: 2% !important;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.Import {
  float: right;
  color: #909399;
  background: #edeef4;
  border: none;
  margin-right: 2%;
}
.fileUpload {
  background: #f2f3f9 !important;
  border-radius: 2px;
  color: #0f38ff !important;
  border: none;
  font-weight: bold;
  padding: 7px 15px;
  font-size: 12px;
  margin-right: 20px;
}
.upload-demo {
  display: inline-block;
}
.topUsers {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.top_header {
  display: flex;
  flex-wrap: wrap;
}
.top_header .el-select {
  width: 17%;
}
div/deep/.topUsers .el-input {
  width: 220px !important;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
.roster_form .el-form-item {
  display: block;
  width: 100%;
}
.detail_desc {
  display: inline-block;
  width: 100%;
  /*1. 先强制一行内显示文本*/
  white-space: nowrap;
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
</style>
